@import './wbce-colors.scss';

div.wbce-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    border: 1px solid $wbce-dark-white;
    overflow: hidden;

    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    height: 100%;
    width: 100%;

    &.margin-card {
        margin-top: 16px;
    }

    .wbce-card-header {
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @extend .mat-subtitle-1 !optional;
        margin-bottom: 0px !important;
        background-color: $wbce-dark-white;
        border: 1px solid rgba($wbce-dark-blue, 0.1);
        border-radius: 8px 8px 0px 0px;

        .wbce-card-title {
            margin-left: 12px;
            @extend .theming-notch !optional;
            min-height: 36px;

            &.purple {
                &::before {
                    border-color: $wbce-purple !important; // check with a config file
                }
            }

            &.yellow {
                &::before {
                    border-color: $wbce-yellow !important; // check with a config file
                }
            }

            &.red {
                &::before {
                    border-color: $wbce-red !important; // check with a config file
                }
            }

            &.orange {
                &::before {
                    border-color: $wbce-orange !important; // check with a config file
                }
            }
        }
    }

    .wbce-card-scrollable {
        overflow-y: scroll;
        scrollbar-gutter: stable;
        max-height: 100%;
    }

    .wbce-card-content {
        padding: 8px;
        height: 100%;
        overflow: auto;
    }

    .wbce-card-content.columns {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .column {
            flex: 33.33%;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            fieldset {
                // later move fieldset globally
                border-color: $wbce-medium-grey;
                width: 100%;
                height: 50px;
            }

            &.border {
                flex: 30%;
                min-width: 160px;
            }

            &.center {
                flex: 40%;
                max-width: 300px;
            }
        }
    }
}

/* On mouse-over, add a deeper shadow */
div.wbce-card:hover {
    // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
    box-shadow: none;
}

.wbce-card-content-action {
    width: 100%;
    display: flex;
    gap: 32px;
    justify-content: flex-end;
    align-items: center;
}

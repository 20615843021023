@import './wbce-colors.scss';

table {
    width: 100%;
}

tr.mat-mdc-header-row {
    height: 40px;
    background-color: $wbce-dark-white;
    h4 {
        color: black;
    }
}

tr.mat-mdc-row {
    height: 40px;
}

tr.mat-mdc-footer-row {
    font-weight: bold;
}

th h4 {
    margin-bottom: 0px !important;
    @extend .theming-notch !optional;
}

.mat-mdc-paginator {
    height: 40px !important;
    background-color: $wbce-dark-white !important;
}

.mat-mdc-no-data-row td {
    height: 40px !important;
    padding-left: 16px;
    padding-right: 16px;
}
